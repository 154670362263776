<template>
    <templete-form-component mode="edit" />
</template>
<script>
import templeteFormComponent from './components/templeteFormComponent.vue';
export default {
    components: {
        templeteFormComponent
    }
}
</script>